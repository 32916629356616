<script setup lang="ts">
import LayoutHeader from '@/components/LayoutHeader.vue'
import LayoutFooter from '@/components/LayoutFooter.vue'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
  <LayoutHeader />
  <main class="frame">
    <p>{{ t('notFound') }}</p>
  </main>
  <LayoutFooter />
</template>
<style scoped>
p {
  margin-top: 32px;
}
.frame,
:deep(.frame) {
  width: 1060px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .frame {
    margin: 0 16px;
    width: auto;
    max-width: unset;
  }
}
</style>

<i18n lang="yaml">
en:
  notFound: 'Page not found.'
ja:
  notFound: 'お探しのページが見つかりませんでした。'
</i18n>
